<template>
  <div class="page-wrapper">
    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <div class="">
      <div class="container-default-1209px w-container">
        <div
          class="w-full flex justify-center items-center"
          style="height: 600px"
        >
          <div class="w-full">
            <div class="w-full slides relative">
              <div
                class="absolute top-[30%] right-[50%] w-full flex flex-col items-center justify-center z-[500]"
              >
                <img
                  src="../assets/srclogo.png"
                  alt=""
                  class="h-48 object-contain"
                />
                <!-- <p class="pt-2 text-[#ffffff] font-bold text-3xl">
                  SOUNDARYA EDUCATIONAL TRUST
                </p> -->
              </div>
              <img
                src="../assets/background.png"
                alt=""
                class="h-96 w-full rounded px-2 object-contain"
              />
              <!-- <img src="../assets/3.png" alt="" class="h-96 w-full rounded object-fill px-2 slide"> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-default-1209px w-container">
      <div
        data-w-id="0b097d78-b776-9e09-a4b6-8ab6576371c3"
        style=""
        class="divider"
      ></div>
    </div>

    <div
      id="Courses"
      data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384279"
      style=""
      class="section latest-courses wf-section"
    >
      <div class="container-default-1209px w-container">
        <div class="latest-courses-wrapper">
          <h2
            data-w-id="4dfb3ae6-c85f-5256-cad4-afe06038427c"
            style=""
            class="title latest-courses"
          >
            My Courses
          </h2>
          <div class="w-dyn-list">
            <div
              v-if="this.allyourcourse"
              role="list"
              class="courses-grid w-dyn-items"
            >
              <div
                v-for="course in this.allyourcourse"
                :key="course"
                data-w-id="96a61bf5-bd59-e173-033b-ad374d548848"
                style="height: 528px"
                role="listitem"
                class="course-card-wrapper w-dyn-item w-80 md:w-96"
              >
                <div class="card w-80 md:w-96" style="height: 528px">
                  <router-link
                    :to="{
                      name: 'VideoRender',
                      params: { courseid: course.id },
                    }"
                    class="card w-80 md:w-96"
                    style="height: 528px"
                  >
                    <a href="#" class="card-image-link w-inline-block"
                      ><img
                        :src="course.thumbnail"
                        alt=""
                        style="overflow: hidden; height: 208px"
                        class="image course w-80 md:w-96"
                    /></a>
                    <div class="card-content">
                      <a href="#" class="card-title-link w-inline-block">
                        <h3 class="text-lg 2xl:text-xl course">
                          {{ course.title.slice(0, 80) }}
                          <span v-if="course.title.length > 80">....</span>
                        </h3>
                      </a>
                      <p class="text-base primary-text-color">
                        {{ course.short_description.slice(0, 300) }}
                        <span v-if="course.short_description.length > 300"
                          >....</span
                        >
                      </p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-else class="card empty-state w-dyn-empty">
              <div class="empty-state-text">
                There are no courses available yet.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-default-1209px w-container">
      <div
        data-w-id="0b097d78-b776-9e09-a4b6-8ab657637223"
        style=""
        class="divider"
      ></div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      courselist: "",
      blog_list: "",
      webinar_List: "",
      messages: {
        text: "",
        type: "null",
      },
      title: "",
      purchased_course: "",
      allyourcourse: "",
      listOfcourse: [],
    };
  },
  computed: mapGetters(["isAuthenticated", "userInfo"]),
  components: {
    Navigation,
    Footer,
    FlashMessage,
  },

  async created() {
    this.title = process.env.VUE_APP_Title;

    await axios
      .get(`${process.env.VUE_APP_API}/courses/?format=json`)
      .then((response) => {
        this.courselist = response.data.data;
      });

    await axios
      .get(
        `${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`
      )
      .then((response) => {
        this.purchased_course = response.data;

        if (this.purchased_course.data.length > 0) {
          for (let el in this.purchased_course.data) {
            this.listOfcourse.push(this.purchased_course.data[el].course_id);
          }
          this.allyourcourse = this.courselist.filter((el) =>
            this.listOfcourse.includes(el.id)
          );
        } else {
          console.log("else");
        }
      });
  },

  methods: {},
};
</script>

<style scoped></style>
