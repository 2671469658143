<template>
  <div class="h-screen w-full flex items-center justify-center">
    <img src="@/assets/load-35_256.gif" alt="" />
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
export default {
  created() {
    this.tokenBasedLogin();
  },
  props: ["token"],
  methods: {
    ...mapMutations(["setUserInfo"]),
    tokenBasedLogin() {
      axios
        .get(`${process.env.VUE_APP_API}/general/login/${this.token}/`)
        .then((resp) => {
          this.setUserInfo(resp.data);
          setTimeout(() => {
            this.$router.push({
              name: "UserProfile",
            });
          }, 3000);
        });
    },
  },
};
</script>

<style></style>
